@import 'src/variables.scss';

//PICKERS

.MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar,
    .MuiPickersDay-daySelected,
    .MuiPickersClockNumber-clockNumberSelected,
    .MuiPickersClockPointer-pointer,
    .MuiPickersClock-pin,
    .MuiPickersDay-daySelected:hover,
    .MuiPickerDTTabs-tabs {
        background-color: $primary_color;
        color: white !important;
    }

    .MuiPickersClockPointer-thumb {
        border-color: $primary_color;
    }

    .MuiPickersDay-current {
        color: $primary_color;
    }
}

//FIELDS

.MuiButton-textPrimary {
    color: $primary_color !important;
}

.MuiInputBase-root.Mui-disabled {
    background: $disabled_color;
}

.MuiCheckbox-colorPrimary.Mui-checked,
.MuiIconButton-colorPrimary {
    color: $primary_color !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: $primary_color !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $primary_color !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #474747 !important;
    color: #474747 !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #696969 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #474747 !important;
}

.MuiInput-underline:after {
    border-bottom-color: $primary_color !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $primary_color !important;
}

.MuiFormControl-root {
    .MuiInputLabel-outlined {
        outline: none !important;
    }
    .MuiInputBase-root {
        border-radius: 6px;
    }
}

.week-selector,
.month-selector {
    background-color: white;
    border-radius: 50px;

    min-width: fit-content;
    width: 100%;
    max-width: 350px;

    height: 60px;

    button {
        border-radius: 50px;
        min-width: 50px;
        min-height: 50px;
    }
}

.month-selector {
    .current-month {
        font-size: 20px;
        font-weight: 500;
    }
}

.week-selector {
    .current-week {
        font-size: 20px;
        font-weight: 500;
        padding-top: 5px;
    }

    .time-delta {
        font-size: 13px;
        padding-bottom: 5px;
    }
}

.employee-selector {
    background-color: white;
    border-radius: 50px;

    button {
        border-radius: 50px;
        min-width: 50px;
        min-height: 50px;
    }

    .employee-name {
        padding-top: 5px;
    }

    .employee-name,
    .employee-surname {
        font-size: 16px;
    }
}

// .filepond--root,
// .filepond--root .filepond--drop-label {
//     height: 110px;
// }

.button-action {
    background-color: $primary_color !important;
    color: white !important;
    //font-weight: bold !important;
}

.button-action.Mui-disabled {
    opacity: 0.4 !important;
}

// .MuiFormControl-root {
//     input:not(.MuiOutlinedInput-input) {
//         height: 40px;
//     }
// }

.SNC-control-readonly {
    &:before,
    &:after {
        border-bottom: transparent !important;
    }

    .MuiSelect-icon {
        display: none !important;
        // .MuiInputAdornment-root {
        //     display: none !important;
        // }
    }
}

// .url-field {
//     .MuiInputLabel-formControl {
//         transform: none !important;
//     }
// }

.mui-date-picker {
    .SNC-control-readonly {
        .MuiInputAdornment-root {
            display: none !important;
        }
    }
}

.MuiFab-root {
    height: 42px !important;
    padding: 0 14px !important;
    min-width: 40px !important;
    border-radius: 22px !important;
}

.MuiBadge-colorSecondary {
    background-color: $primary_color !important;
    opacity: 0.8;
}
