@import 'src/variables.scss';

.snc-emi-administration-area {
    .snc-emi-administration-menu {
        width: 300px;
        height: 100%;
        border-radius: $border-radius;

        .snc-accordeon {
            height: 100%;

            .MuiPaper-root {
                height: 100%;
            }
        }
    }

    .MuiExpansionPanel-root {
        background: $grey_blue_dark_color;
        color: $white;
    }

    .icon-white {
        color: $white;
    }
}

@include media-breakpoint-down(lg) {
    .snc-emi-administration-area {
        .snc-emi-administration-menu {
            width: 100%;
            height: unset;
        }
    }
}
