@import 'src/variables.scss';

.launcher-applications-title,
.launcher-external-title {
    text-transform: uppercase;
    padding-bottom: 0;
    margin-bottom: 0;
}

.external-links-container {
    max-height: 400px;
}

.limited-with-1200 {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.emi-launcher-app-entry {
    background-color: $white;
    border-radius: 10%;
}
