@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints';

$primary_color: var(--primary_color, #c30117) !default;
$secondary_color: #212529 !default;

$color_transparent: transparent !default;
$color_white: #fff !default;
$color_black: #000 !default;

$grey_blue_dark_color: #212529 !default;
$grey_blue_light_color: #adb5bd !default;
$color_grey: #919191 !default;
$color_grey_2: #5e656b !default;

$background_color: #e3e3e3 !default;
$darker_background_color: #d6d6d6 !default;

$disabled_color: #eaeaea !default;

$dark_tabs_background_color: #c30117 !default;

$color_action: #ea001b !default;

$border-radius: 0.4rem;
