@import 'src/variables.scss';

.limited-width-800 {
    max-width: 800px;
}

.limited-width-1200 {
    max-width: 1200px;
}

.limited-width-1600 {
    max-width: 1600px;
}

.limited-width-800,
.limited-width-1200,
.limited-width-1600 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.snc-paper-dark {
    background-color: $grey_blue_dark_color;
    color: $color_white;
}

.snc-paper {
    background-color: $color_white;
}

.snc-paper,
.snc-paper-dark {
    border-radius: $border-radius;
    box-shadow: 0 4px 10px -10px #000 !important;
}

.rounded {
    border-radius: $border-radius !important;
}

.action-icon-button {
    font-size: 1.5em;
}

.horizontal-scrollable {
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto !important;
}

.vertical-scrollable {
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto !important;
}

.row-content {
    min-height: 400px;
    height: 50%;
}

.main-content {
    height: 100%;
}

hr.red-hr {
    border-top: 1px solid rgba(128, 128, 128, 0.25);
    margin-top: 1.5rem;
    margin-bottom: 0px;
}

.snc-bg-color {
    background-color: $background_color;
}

.snc-darker-bg-color {
    background-color: $darker_background_color;
}

// class to use in those cases where in mobile the full content should be scrollable
// for example when having forms which will grow fast in height due to the
// responsive behaviour
.mobile-scrollable-container {
    height: 100%;
    // min-height: 800px;
}

.MuiPopover-root {
    .MuiMenu-paper {
        max-height: 50%;
        max-width: 300px;
    }
}

.col-same-height {
    display: flex !important;

    > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
}

::-webkit-scrollbar {
    width: 17px;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 17px 17px transparent;
    border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 17px 17px #bbbbbe;
    border: solid 4px transparent;
    border-radius: 17px;
}

::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 17px 17px #9c9c9e;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

@include media-breakpoint-down(lg) {
    .main-content {
        height: unset !important;
        // min-height: calc(max(100vh - 61px, 800px));
    }

    .main-content-child {
        // min-height: fit-content;
        // height: 1px;
    }
}
