@import 'src/variables.scss';

.errorMessage {
    background-color: #f9ebeb;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Roboto';
    color: #911d22;
    overflow: hidden;

    .mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .container,
    .container > .row,
    .container > .row > div {
        height: 100%;
    }

    .countUp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .text {
        font-size: 2rem;
        text-align: center;
    }
}
