@import 'src/variables.scss';

p,
.snc-label {
    margin-bottom: 0px;
}

.snc-label {
    font-weight: 600;
}

.section-label {
    color: $primary_color;
}
